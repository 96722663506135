<template>
  <div class="page store settlement result" ref="content">
    <div class="ibox ">
      <div class="ibox-content">

        <h1>정산서</h1>

        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead v-if="profitData">

              <tr class="fields text-left" >
                <th :colspan="columns">

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">상점명</label>
                    <div class="col-sm-10">
                      {{profitData.store_name_localed}}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">정산일</label>
                    <div class="col-sm-10">
                      {{profitData.settled_date_start}} ~ {{profitData.settled_date_end}}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">환율</label>
                    <div class="col-sm-10">
                      {{profitData.exchange_rate}}
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">수익률</label>
                    <div class="col-sm-10">
                      {{profitData.profit_rate}} %
                    </div>
                  </div>

                  <!-- <div class="form-group row"><label class="col-sm-2 col-form-label">주문 당 제반비용</label>
                    <div class="col-sm-10">
                      {{profitData.expenses_per_order_comment}}
                      {{profitData.expenses_per_order}} 원
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">취소 당 제반비용</label>
                    <div class="col-sm-10">
                      {{profitData.expenses_per_cancel_comment}}
                      {{profitData.expenses_per_cancel}} 원
                    </div>
                  </div> -->

                  <div class="form-group row"><label class="col-sm-2 col-form-label">기타 제반비용</label>
                    <div class="col-sm-10">
                      {{profitData.expenses_etc_comment}}
                      {{profitData.expenses_etc}} 원
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">정산 상태</label>
                    <div class="col-sm-10">
                      {{profitData.status}}
                    </div>
                  </div>

                </th>
              </tr>
              <tr>
                <th :colspan="columns">
                  <div class="info">Showing {{page}} to {{Math.max(1, parseInt(total/size))}} of <strong>{{total}}</strong> entries</div>
                  <div class="padding"></div>
                </th>
              </tr>
            </thead>
            

            <div class="html2pdf__page-break"></div>
            <thead>
              <tr class="fields">
                <th scope="col">#</th>
                <th scope="col">결제시간</th>
                <th scope="col">결제방식</th>
                <th scope="col">결제단위</th>
                <th scope="col">결제금액</th>
                <th scope="col">취소시간</th>
                <th scope="col">주문상태</th>
                <th scope="col">티켓상태</th>
              </tr>
            </thead>
            
            <tbody v-if="profitData">
              <tr v-for="row in items" :key="row.uid">
                <td>{{row.uid}}</td>
                <td>{{row.paid_at && $moment.tz(row.paid_at, "UTC").tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")}}</td>
                <td>{{row.payment_method}}</td>
                <td>{{row.paid_unit}}</td>
                <td>{{row.paid_total}}</td>
                <td>{{row.cancelled_at && $moment.tz(row.cancelled_at, "UTC").tz("Asia/Seoul").format("YYYY-MM-DD HH:mm:ss")}}</td>
                <td>{{row.display_status}}</td>
                <td>{{row.display_ticket_status}}</td>
              </tr>
            </tbody>

            <div class="html2pdf__page-break"></div>

            <tbody v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>달러 주문건 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.ordered)}}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.paid)}}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.cancelled)}}</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.profit)}}</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">
                  <h4>건수 / 수량</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.paid_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity_paid)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity_paid)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소건수</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.cancel_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_quantity_cancel)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.usd.luggage_over_quantity_cancel)}} 개</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>주문당 제반비용</dt> <dd>{{profitData.extras.usd.order_count}} 개 x {{profitData.expenses_per_order}} 원 = {{$utils.numberFormat(profitData.extras.usd.expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{profitData.extras.usd.cancel_count}} 개 x {{profitData.expenses_per_cancel}} 원 = {{$utils.numberFormat(profitData.extras.usd.expenses_per_cancel)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>PG 수수료</dt> <dd>$ {{$utils.numberFormat(profitData.extras.usd.payment_fee)}}</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
            </tbody>

            <tbody v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>원화 주문건 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.ordered)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.paid)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.cancelled)}} 원</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.profit)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">
                  <h4>건수 / 수량</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.paid_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity_paid)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity_paid)}} 개</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소건수</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.cancel_count)}} 건</dd>
                      </dl>
                      <dl>
                        <dt>작은물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_quantity_cancel)}} 개</dd>
                      </dl>
                      <dl>
                        <dt>큰물품</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.luggage_over_quantity_cancel)}} 개</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>주문당 제반비용</dt> <dd>{{profitData.extras.krw.order_count}} 개 x {{profitData.expenses_per_order}} 원 = {{$utils.numberFormat(profitData.extras.krw.expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{profitData.extras.krw.cancel_count}} 개 x {{profitData.expenses_per_cancel}} 원 = {{$utils.numberFormat(profitData.extras.krw.expenses_per_cancel)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>PG 수수료</dt> <dd>{{$utils.numberFormat(profitData.extras.krw.payment_fee)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
            </tbody>

            <tfoot v-if="profitData">
              <tr>
                <td :colspan="columns" class="text-left" >
                  <h3>총 합계 </h3>

                  <h4>주문 합계</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>주문금액</dt> <dd>{{$utils.numberFormat(profitData.total_ordered)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>결제금액</dt> <dd>{{$utils.numberFormat(profitData.total_paid)}} 원</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>취소금액</dt> <dd>{{$utils.numberFormat(profitData.total_cancelled)}} 원</dd>
                      </dl>
                    </li>
                    <!-- <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(profitData.total_profit)}} 원</dd>
                      </dl>
                    </li> -->
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">

                  <h4>제반비용 </h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>PG 결제 수수료</dt> <dd>{{$utils.numberFormat(profitData.total_payment_fee)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>기타 제반 비용</dt> <dd>{{$utils.numberFormat(profitData.expenses_etc)}} 원</dd>
                      </dl>                      
                    </li>                
                    <li>
                      <dl>
                        <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(profitData.expenses_basic)}} 원</dd>
                      </dl>
                      <!-- <dl>
                        <dt>주문당 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_per_order)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>취소당 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_per_cancel)}} 원</dd>
                      </dl> -->
                    </li>
                    <li>
                      <dl>
                        <dt>총 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses)}} 원</dd>
                      </dl>
                    </li>
                  </ul>

                </td>
              </tr>
              <tr>
                <td :colspan="columns" class="text-left">

                  <h4>호스트 수익</h4>

                  <ul class="total">
                    <li>
                      <dl>
                        <dt>총 매출</dt> <dd>{{profitData.total_paid}} 원</dd>
                      </dl>
                      <dl>
                        <dt>공동 부담 제반비용</dt> <dd>{{profitData.total_payment_fee + profitData.expenses_etc}} 원</dd>
                      </dl>                      
                      <dl>
                        <dt>총 수익</dt> <dd>{{profitData.total_paid - profitData.total_payment_fee - profitData.expenses_etc}} 원</dd>
                      </dl>    
                    </li>
                    <li>
                      <dl>
                        <dt>수익률</dt> <dd>{{profitData.profit_rate}} %</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>호스트 매출</dt> <dd>{{$utils.numberFormat(profitData.total_profit)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>호스트 부담 제반비용</dt> <dd>{{$utils.numberFormat(profitData.total_expenses_basic)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>호스트 수익</dt> <dd>{{$utils.numberFormat(profitData.total_income)}} 원</dd>
                      </dl>                      
                      <!-- <dl>
                        <dt>세금</dt> <dd>{{$utils.numberFormat(profitData.total_income_tax)}} 원</dd>
                      </dl>
                      <dl>
                        <dt>공급가액</dt> <dd>{{$utils.numberFormat(profitData.total_income_amount)}} 원</dd>
                      </dl> -->
                    </li>
                  </ul>

                </td>
              </tr>

              <tr v-if="profitData.status === 'NEW'">
                <td :colspan="columns" class="fields text-left">

                  <h3>은행 계좌 정보</h3>

                  <div class="group">
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">계좌 선택</label>
                      <div class="col-sm-10">
                        <BankAccountSelect v-model="bankAccountData.uid" :user-id="profitData.user_id" name="bank_account_id" @selected-data="onSelectedData" />
                      </div>
                    </div>

                    <template v-if="bankAccountData.uid">

                      <div class="form-group row"><label class="col-sm-2 col-form-label">예금주명</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="bank_owner_name" readonly="true" v-model="bankAccountData.bank_owner_name" />
                        </div>
                      </div>

                      <div class="form-group row"><label class="col-sm-2 col-form-label">은행명</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="bank_name" readonly="true" v-model="bankAccountData.bank_name" />
                        </div>
                      </div>

                      <div class="form-group row"><label class="col-sm-2 col-form-label">은행계좌</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="bank_name" readonly="true" v-model="bankAccountData.bank_account" />
                        </div>
                      </div>
                    </template>

                    <template v-if="!bankAccountData.uid">
                      <div class="form-group row"><label class="col-sm-2 col-form-label">예금주명</label>
                        <div class="col-sm-10">
                          <input class="form-control" type="text" name="bank_owner_name" v-model="bankAccountData.bank_owner_name" />
                        </div>
                      </div>

                      <div class="form-group row"><label class="col-sm-2 col-form-label">은행명</label>
                        <div class="col-sm-10">
                          <input class="form-control" type="text" name="bank_name" v-model="bankAccountData.bank_name" />
                        </div>
                      </div>

                      <div class="form-group row"><label class="col-sm-2 col-form-label">은행계좌</label>
                        <div class="col-sm-10">
                          <input class="form-control" type="text" name="bank_name" v-model="bankAccountData.bank_account" />
                        </div>
                      </div>
                    </template>
                  </div>

                </td>
              </tr>

              <tr>
                <td :colspan="columns" class="fields text-left">
                  <h3>정산 정보</h3>
                  
                  <div class="group">
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">정산 방식</label>
                      <div class="col-sm-10">
                        <input class="form-control text" type="text" name="settled_method" readonly="true" v-model="data.settled_method" />
                      </div>
                    </div>

                    <template v-if="profitData.status !== 'NEW'">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">예금주명</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="settled_bank" readonly="true" v-model="profitData.settled_bank_owner_name" />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">은행명</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="settled_total" readonly="true" v-model="profitData.settled_bank_name" />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">은행계좌</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="settled_total" readonly="true" v-model="profitData.settled_bank_account" />
                        </div>
                      </div>

                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">정산 단위</label>
                      <div class="col-sm-10">
                        <input class="form-control text" type="text" name="settled_method" readonly="true" v-model="data.settled_unit" />
                      </div>
                    </div>

                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">정산 금액</label>
                        <div class="col-sm-10">
                          <input class="form-control text" type="text" name="settled_total" readonly="true" v-model="profitData.settled_total" />
                        </div>
                      </div>
                    </template>

                    <template v-if="profitData.status === 'NEW'">

                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">정산 금액</label>
                        <div class="col-sm-10">
                          <input class="form-control" type="text" name="settled_total" readonly="true" v-model="data.settled_total" />
                        </div>
                      </div>
                    </template>

                  </div>
                  
                </td>
              </tr>



              <tr>
                <td :colspan="columns" class="actions no-print" v-if="!printing">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickClose">Close</button>
                  </div>
                  <div class="right">
                    <button type="button" class="btn btn-primary btn-download" @click="onClickDownload">PDF</button>
                    <button type="button" class="btn btn-primary btn-submit" @click="onClickPrint">Print</button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/elements/Pagination"
import BankAccountSelect from "@/components/utils/BankAccountSelect"
import html2pdf from "html2pdf.js"

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model

    this.loadData()
  },
  data() {
    return {
      columns: 8,
      mode: 'unset',
      model: {},
      page: 1,
      size: 10,
      total: 0,
      profitData: null,
      bankAccountData: {},
      data: {
        settled_method: 'BANK',
        settled_unit: 'KRW',
        settled_total: 0,
      },
      items: [],
      date_start: '',
      date_end: '',
      printing: false,
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(this.model.types.get.action, this.$route.params)
        .then((data) => {
          this.$data.profitData = data
          this.$data.items = data.items
          this.$data.total = data.items.length

          if (data.status === "NEW") {
            this.$data.data.settled_total = data.total_income
          }
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onSelectedData(data) {
      this.$data.bankAccountData = data
    },

    onClickDelete() {
      let params = { uid: this.$route.params.uid + '' }

      this.$store.dispatch(this.model.types.delete.action, params)
        .then((data) => {
          this.$toast.success("정산이 삭제되었습니다")

          this.$router.back()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClickRequest() {
      let params = { 
        uid: this.$route.params.uid + '', 
        
        bank_owner_name: this.bankAccountData.bank_owner_name,
        bank_name: this.bankAccountData.bank_name,
        bank_code: this.bankAccountData.bank_code,
        bank_account: this.bankAccountData.bank_account,

        settled_method: this.data.settled_method,
        settled_unit: this.data.settled_unit,
        settled_total: this.data.settled_total
      }

      this.$store.dispatch(this.model.types.request.action, params)
        .then((data) => {
          this.$toast.success("요청하였습니다.")

          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClickDownload() {
      let opt = {
        margin:       0,
        filename:     `${this.profitData.store_name_localed}.pdf`,
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak:    { mode: ['avoid-all', 'legacy'] }
      };
      this.$data.printing = true
      
      html2pdf().set(opt).from(this.$refs.content).save();

      this.$data.printing = false
    },

    onClickClose() {
      window.close()
    },

    onClickPrint() {
      window.print()
    },

  },
  components: {
    Pagination, BankAccountSelect
  }
}
</script>
